import React, { useEffect, useState } from 'react';
import Icon from '../../../ui/shared/Icon';
import CalendarPickerModal from '../../../ui/shared/Modals/CalendarPicker.modal';
import MostExceptionsSettingsModal from '../../../ui/shared/Modals/most-exceptions/MostExceptionsSettings.modal';
import MostExceptionsDetailsModal from '../../../ui/shared/Modals/most-exceptions/MostExceptionsDetails.modal';
import { DataGrid, type GridInitialState, type GridColDef, GridToolbar } from '@mui/x-data-grid';
import { appState, useSignal, handleDataGridStateChange } from '../../../index';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Tooltip from '@mui/material/Tooltip';
import axios from 'axios';
import { CircularProgress } from '@mui/material';
import { iconColorMap, iconTypeMapping } from '../../../helpers/types';
import { type IconName } from '@fortawesome/fontawesome-svg-core';
import { t } from 'i18next';
import { withTranslation } from 'react-i18next';
import { AUTHORIZED_ROUTES } from '../../../enums/ROUTES';
import { openToast } from '../../../helpers/toast';
import SearchButton from '../../../ui/shared/SearchButton';
import { isValidCell } from '../../../helpers/columns';

const MostExceptions: React.FC = () => {
  const showAlerts = localStorage.getItem('accountName') === 'CDOT';
  const updatedAccountId = useSignal(appState.accountId);
  const [activeUserId, setActiveUserId] = useState<number | null>(null);
  const [rowsLength, setRowsLength] = useState(0);
  const [worstOffenders, setWorstOffenders] = useState<any>([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isDetailsModalOpen, setIsDetailsModalOpen] = useState<boolean>(false);
  const [settingsUpdated, setSettingsUpdated] = useState(false);
  const [typingTimeout, setTypingTimeout] = useState<number>(0);
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredData, setFilteredData] = useState([]);
  appState.currentRoute.value = AUTHORIZED_ROUTES.MOST_EXCEPTIONS;

  const fetchMostExceptions = async (): Promise<any> => {
    try {
      setIsSubmitting(true);
      const url = `${process.env.REACT_APP_API_URL}/api/get-most-exceptions`;
      const response = await axios.post(url);
      if (response?.data?.data) {
        setWorstOffenders(response.data.data.sort((a: any, b: any) => {
          if (a.typeCounts.total_count < b.typeCounts.total_count) return 1;
          if (a.typeCounts.total_count > b.typeCounts.total_count) return -1;
          else return 0;
        }));
      }
      setIsSubmitting(false);
    } catch (error) {
      console.log('Error getting most exceptions: ', error);
      setIsSubmitting(false);
    }
  };

  const handleDatesSubmit = async (values: any): Promise<any> => {
    if (values.to && values.from) {
      setIsSubmitting(true);
      // Call the handleDateChange function when the dates are submitted
      const fromDate = new Date(values.from.year, values.from.month - 1, values.from.day);
      const toDate = new Date(values.to.year, values.to.month - 1, values.to.day, 23, 59, 59);
      const params = {
        start_date: fromDate.getTime() / 1000,
        end_date: toDate.getTime() / 1000
      };
      try {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/get-most-exceptions`, params);
        if (response?.data?.data) {
          setWorstOffenders(response.data.data);
        }
        setIsSubmitting(false);
      } catch (error) {
        console.log('Error getting most exceptions: ', error);
        setIsSubmitting(false);
      }
    } else {
      openToast({ type: 'error', label: t('Invalid date selection'), autoClose: 2000, theme: 'dark' });
    }
  };

  interface WorstOffenders {
    row: {
      typeCounts: Record<string, any>,
      [key: string]: any,
    };

    [key: string]: any;
  }

  const getValue = (obj: WorstOffenders | undefined, path: string): any => {
    return obj && path.split('.').reduce((acc, part) => acc?.[part], obj);
  };

  interface HeaderTooltipProps {
    title: string;
    field: string;
  }

  const HeaderTooltip: React.FC<HeaderTooltipProps> = ({ title, field }) => {
    const iconName: IconName = iconTypeMapping[field];
    const iconColor = iconColorMap[iconName as keyof typeof iconColorMap];

    return (
      <Tooltip title={t(title)}>
        <div className={`flex items-center ${iconColor}`}>
          {iconName
            ? (<Icon name={iconName}/>)
            : (<span>{t(title)}</span>)
          }
        </div>
      </Tooltip>
    );
  };

  HeaderTooltip.displayName = 'HeaderTooltip';

  const formatCurrency = (value: number | undefined | null): string => {
    if (value !== undefined && value !== null) {
      return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD'
      }).format(value);
    } else {
      return '';
    }
  };

  const columnData = [
    { field: 'group', title: 'Transaction Group', icon: iconTypeMapping.group },
    { field: 'no_vehicle_location', title: 'No Vehicle Location', icon: iconTypeMapping.no_vehicle_location },
    { field: 'location_mismatch', title: 'Location Mismatch', icon: iconTypeMapping.location_mismatch },
    { field: 'fuel_level_mismatch', title: 'Fuel Level Mismatch', icon: iconTypeMapping.fuel_level_mismatch },
    { field: 'fuel_type_mismatch', title: 'Fuel Type Mismatch', icon: iconTypeMapping.fuel_type_mismatch },
    { field: 'filling_time_alert', title: 'Filling Time Alert', icon: iconTypeMapping.filling_time_alert },
    { field: 'filling_percent_alert', title: 'Filling Percent Alert', icon: iconTypeMapping.filling_percent_alert },
    { field: 'tank_capacity_mismatch', title: 'Tank Capacity Mismatch', icon: iconTypeMapping.tank_capacity_mismatch },
    ...(showAlerts ? [{ field: 'out_of_state_alert', title: 'Out of State', icon: iconTypeMapping.out_of_state_alert }] : [])
  ];

  const columns: GridColDef[] = [
    {
      type: 'string',
      width: 200,
      field: 'driver',
      headerName: 'Driver Name',
      renderCell: (worstOffenders: WorstOffenders) => (
        <button
          type="button"
          className="text-custom-blue-normal hover:text-custom-blue-hover"
          onClick={() => {
            setIsDetailsModalOpen(true);
            setActiveUserId(worstOffenders.row.id);
          }}
        >
          {!isValidCell(worstOffenders.row.driver) ? 'No Driver Name' : worstOffenders.row.driver}
        </button>
      )
    },
    {
      type: 'string',
      width: 100,
      field: 'total_count',
      valueGetter: (worstOffenders: WorstOffenders) => getValue(worstOffenders, 'row.typeCounts.total_count'),
      headerName: 'Total'
    },
    ...columnData.map(data => ({
      type: 'string',
      renderHeader: (params: any) => {
        const iconName: IconName = iconTypeMapping[data.field];
        const iconColor = iconColorMap[iconName as keyof typeof iconColorMap];

        return (
          <Tooltip title={t(data.title)}>
            <div className={`flex items-center ${iconColor}`}>
              {iconName
                ? (<Icon name={iconName}/>)
                : (<span>{t(data.title)}</span>)
              }
            </div>
          </Tooltip>
        );
      },
      width: 70,
      field: data.field,
      valueGetter: (worstOffenders: WorstOffenders) => getValue(worstOffenders, `row.typeCounts.${data.field}`),
      headerName: data.title
    })),
    {
      type: 'number',
      width: 200,
      field: 'amt_money_in_dispute',
      headerName: t('Amount of Money in Dispute'),
      valueGetter: (worstOffenders: WorstOffenders) => getValue(worstOffenders, 'row.money'),
      valueFormatter: ({ value }: { value: number, }) => formatCurrency(value)
    },
    {
      type: 'number',
      width: 240,
      field: 'amt_fuel_in_dispute',
      headerName: t('Amount of Fuel in Dispute'),
      valueGetter: (worstOffenders: WorstOffenders) => getValue(worstOffenders, 'row.fuel'),
      valueFormatter: ({ value }: { value: number, }) => value !== null && value !== undefined ? value.toFixed(2) : ''
    }
  ];

  const handleSearchQueryChange = (query: string): void => {
    window.clearTimeout(typingTimeout);

    // Waits until user is finished typing
    const newTimer: number = window.setTimeout((): void => {
      setSearchQuery(query.trim());
    }, 1500);
    setTypingTimeout(newTimer);
  };

  const theme = createTheme({
    palette: {
      mode: 'dark'
    }
  });

  const worstOffendersWithFallback = worstOffenders.map((worstOffender: any) => {
    const updatedTypeCounts: any = {};
    const naColumns = ['amt_money_in_dispute', 'amt_fuel_in_dispute'];
    columns.forEach((column) => {
      const columnName = column.field;
      updatedTypeCounts[columnName] =
        worstOffender.typeCounts[columnName] < 0
          ? 0
          : worstOffender.typeCounts[columnName] !== undefined
            ? worstOffender.typeCounts[columnName]
            : (naColumns.includes(columnName)
                ? 'N/A'
                : 0); // placeholders for missing data
    });
    return { ...worstOffender, typeCounts: updatedTypeCounts };
  });

  useEffect(() => {
    void fetchMostExceptions();
    setRowsLength(() => {
      if (worstOffendersWithFallback.length > 0) {
        return worstOffendersWithFallback.length;
      } else {
        return 25;
      }
    });
    setSettingsUpdated(false);
  }, [settingsUpdated, updatedAccountId]);

  useEffect(() => {
    if (searchQuery) {
      const newFilteredData = worstOffendersWithFallback.filter((item: any) =>
        item.driver.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.fuel.toString().includes(searchQuery) ||
        item.money.toString().includes(searchQuery)
      );
      setFilteredData(newFilteredData);
    } else {
      setFilteredData(worstOffendersWithFallback);
    }
  }, [searchQuery, worstOffendersWithFallback]);

  return (
    <div className="bg-dark-3 p-4 rounded-xl">
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-x-2 text-custom-gray-light">
          <Icon name="users-line"/>
          <span>{t('Most Exceptions')}</span>
        </div>
        <div className="flex items-center gap-x-3">
          <SearchButton
              onChange={handleSearchQueryChange}
          />
          <CalendarPickerModal
            handleDatesSubmit={handleDatesSubmit}
          />
          <MostExceptionsSettingsModal
            settingsUpdated={settingsUpdated}
            setSettingsUpdated={setSettingsUpdated}
          />
        </div>
      </div>
      <div style={{ marginTop: '10px' }}>
        <ThemeProvider theme={theme}>
          {/* Display loading spinner when isSubmitting is true */}
          {isSubmitting && (
            <div className="loading-overlay">
              <CircularProgress/>
            </div>
          )}
          <CssBaseline/>
          <DataGrid
            onStateChange={(state: GridInitialState): void => {
              handleDataGridStateChange('mostExceptions', state);
            }}
            initialState={appState.dataGridColumnState.value.mostExceptions ?? {}}
            disableDensitySelector={true}
            localeText={{
              noRowsLabel: t('No rows'),
              toolbarColumns: t('Columns'),
              toolbarColumnsLabel: t('Columns'),
              toolbarDensity: t('Density'),
              toolbarDensityLabel: t('Density'),
              toolbarDensityCompact: t('Compact'),
              toolbarDensityStandard: t('Standard'),
              toolbarDensityComfortable: t('Comfortable'),
              toolbarQuickFilterPlaceholder: `${t('Search')}...`,
              MuiTablePagination: {
                labelRowsPerPage: t('Rows per Page'),
                labelDisplayedRows: ({ from, to, count }) => `${from}-${to} ${t('of')} ${count}`
              }
            }}
            rows={filteredData}
            columns={columns}
            disableColumnFilter
            disableColumnMenu
            disableRowSelectionOnClick
            density="compact"
            slots={{ toolbar: GridToolbar }}
            slotProps={{
              toolbar: {
                // showQuickFilter: true,
                printOptions: { disableToolbarButton: true },
                csvOptions: { disableToolbarButton: true }
              }
            }}
            className="flex text-sm bg-dark-3 text-white px-4 py-2 rounded-lg gap-x-6 cursor-pointer"
            sx={{
              height: '50rem',
              '& .MuiDataGrid-row': {
                borderRadius: '80px',
                backgroundColor: '#242838',
                marginTop: '4px',
                borderBottom: 'none'
              },
              '& .MuiDataGrid-cell:focus': {
                outline: 'none'
              },
              '& .MuiDataGrid-cell': {
                border: 'none'
              },
              '& .MuiDataGrid-columnHeaders': {
                borderRadius: '80px',
                backgroundColor: '#242838',
                borderBottom: 'none',
                marginBottom: '10px'
              },
              border: 0
            }}
          />
        </ThemeProvider>
      </div>
      <MostExceptionsDetailsModal
        activeUserId={activeUserId}
        worstOffenders={worstOffenders}
        onHide={() => {
          setIsDetailsModalOpen(false);
          setActiveUserId(null);
        }}
        show={isDetailsModalOpen}
        setWorstOffenders={setWorstOffenders}
      />
    </div>
  );
};

export default withTranslation()(MostExceptions);
