import React, { useEffect, useRef, useState } from 'react';
import { t } from 'i18next';
import { withTranslation } from 'react-i18next';
import { Formik } from 'formik';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { CircularProgress, type Theme } from '@mui/material';
import axios from 'axios';
import Menu, { MenuItem } from 'rc-menu';
import Modal from '../../Modal';
import Icon from '../../Icon';
import Input from '../../Formik/Input';
import Button from '../../Button';
import Switch from '../../Switch';
import DropdownV2 from '../../DropdownV2/DropdownV2';
import Calendar from '../../Calendar/Calendar';
import { iconColorMap, iconTypeMapping, type TransactionAlerts, type TransactionAlertName } from '../../../../helpers/types';
import { appState } from '../../../..';
import { openToast } from '../../../../helpers/toast';
import { type Report } from '../../../../types/reports';
import { fetchGeotabVehicles } from '../../../../helpers/api';

const theme: Theme = createTheme({
  palette: {
    mode: 'dark'
  }
});

interface DateRange {
  day: number;
  month: number;
  year: number;
}

interface Props {
  fetchScheduledReports?: () => void;
  method: 'edit' | 'run' | 'schedule';
  existingScheduledReport?: Report | null;
  handleCloseModal: () => void;
}

const today: Date = new Date();
const lastWeek: Date = new Date(today);
lastWeek.setDate(today.getDate() - 7);

const ReportModal: React.FC<Props> = ({
  fetchScheduledReports,
  handleCloseModal,
  method,
  existingScheduledReport = null
}) => {
  const showAlerts = localStorage.getItem('accountName') === 'CDOT';
  const [existingReport, setExistingReport] = useState<Report | null>(existingScheduledReport);
  const gridRef = useRef<HTMLDivElement | null>(null);
  const [vehicles, setVehicles] = useState<any>();
  const [disabled, setDisabled] = useState<boolean>(false);
  const [reportType, setReportType] = useState<ReportType>(t('Vehicles'));
  const [transformedData, setTransformedData] = useState<any[]>([]);
  const [searchValue, setSearchValue] = useState<string>('');
  const [includedData, setIncludedData] = useState<any>([]);
  const [rowCount, setRowCount] = useState<number>(0);
  const [reportTypeString, setReportTypeString] = useState<string>('vehicle');
  const [showError, setShowError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [scheduledDay, setScheduledDay] = useState<any>(existingScheduledReport ? (String(existingScheduledReport?.day[0])).toUpperCase() + String(existingScheduledReport?.day.slice(1)) : 'Monday');
  const [interval, setInterval] = useState<string>(existingScheduledReport ? (String(existingScheduledReport?.interval[0]).toUpperCase() + String(existingScheduledReport?.interval.slice(1))) : 'Weekly');
  const [timezone, setTimezone] = useState<string>(existingScheduledReport?.timezone ?? 'US/Eastern');
  const [alerts, setAlerts] = useState<TransactionAlerts>(existingScheduledReport?.alerts ?? {
    no_vehicle_location: false,
    location_mismatch: false,
    fuel_level_mismatch: false,
    fuel_type_mismatch: false,
    filling_time_alert: false,
    filling_percent_alert: false,
    tank_capacity_mismatch: false,
    out_of_state_alert: false
  });
  const [startDate, setStartDate] = useState<DateRange>({
    year: lastWeek.getFullYear(),
    month: lastWeek.getMonth() + 1,
    day: lastWeek.getDate()
  });
  const [endDate, setEndDate] = useState<DateRange>({
    year: today.getFullYear(),
    month: today.getMonth() + 1,
    day: today.getDate()
  });

  const getVehicleIdsFromGroups = async (): Promise<object> => {
    let vehicleIds: any[] = [];
    const includedGroupIds: any[] = [];
    includedData.forEach((group: any) => {
      includedGroupIds.push(group.id);
    });
    try {
      const url = `${process.env.REACT_APP_API_URL}/api/geotab-vehicle-ids-by-groups`;
      const response = await axios.post(url, {
        group_ids: includedGroupIds
      });
      vehicleIds = response.data.data;
    } catch (err) {
      console.log(err);
    }
    return vehicleIds;
  };

  const getIds = (): object => {
    const ids: any[] = [];
    includedData.forEach((item: any) => {
      ids.push(item.id);
    });
    return ids;
  };

  const runReport = async (values: any): Promise<void> => {
    setLoading(true);
    setShowError(false);
    if (startDate && endDate) {
      if (includedData.length) {
        let valid = false;
        for (const alert in alerts) {
          if (alerts[alert as TransactionAlertName]) valid = true;
        }
        if (valid) {
          const fromDate = new Date(startDate.year, startDate.month - 1, startDate.day);
          const toDate = new Date(endDate.year, endDate.month - 1, endDate.day);

          const startTimestamp = fromDate.getTime() / 1000;
          const endTimestamp = toDate.getTime() / 1000;

          values.includedData = includedData;
          const url = `${process.env.REACT_APP_API_URL}/api/generate-bad-transactions-report-with-request/${appState.accountId.value}`;

          let selectedReportInputs;
          if (reportTypeString === 'vehicle-group') {
            selectedReportInputs = await getVehicleIdsFromGroups();
          } else {
            selectedReportInputs = getIds();
          }

          const params = {
            account_id: appState.accountId.value,
            start_timestamp: startTimestamp,
            end_timestamp: endTimestamp,
            alerts,
            selected_report_ids: selectedReportInputs,
            report_type_string: reportTypeString,
            vehicles
          };
          try {
            const response = await axios({
              url,
              data: params,
              method: 'post',
              responseType: 'blob'
            });
            const blob = new Blob([response.data], { type: response.headers['content-type'] });
            const downloadUrl = window.URL.createObjectURL(blob);
            const filename = `transaction-report-${String(startDate.year) + '-' + String(startDate.month) + '-' + String(startDate.day)}_${String(endDate.year) + '-' + String(endDate.month) + '-' + String(endDate.day)}`;
            const a = document.createElement('a');
            a.href = downloadUrl;
            a.download = filename;
            document.body.appendChild(a);
            a.click();
            setLoading(false);
            handleCloseModal();
          } catch (err) {
            console.error(err);
            openToast({
              type: 'error',
              label: t('Error generating report'),
              autoClose: 2000,
              theme: 'dark'
            });
            setLoading(false);
          }
        } else {
          setErrorMessage(t('No alert types selected'));
          setShowError(true);
          setLoading(false);
          setDisabled(false);
        }
      } else {
        setErrorMessage(t('No items selected'));
        setShowError(true);
        setLoading(false);
        setDisabled(false);
      }
    } else {
      setErrorMessage(t('Invalid date range'));
      setShowError(true);
      setLoading(false);
      setDisabled(false);
    }
  };

  const createScheduledReport = async (values: any, email: string): Promise<void> => {
    values.includedData = includedData;
    const url = `${process.env.REACT_APP_API_URL}/api/report-schedules`;

    let selectedReportInputs;
    if (reportTypeString === 'vehicle-group') {
      selectedReportInputs = await getVehicleIdsFromGroups();
    } else {
      selectedReportInputs = getIds();
    }
    const params = {
      account_id: appState.accountId.value,
      email: email.replaceAll(' ', ''),
      day: scheduledDay.toLowerCase(),
      interval: interval.toLowerCase(),
      time: values.time,
      next_timestamp: '',
      timezone,
      alerts: JSON.stringify(alerts),
      selected_ids: JSON.stringify(selectedReportInputs),
      type: reportTypeString,
      vehicle_groups: reportTypeString === 'vehicle-group'
        ? JSON.stringify(includedData.map((data: any) => {
          return data.group_id;
        }))
        : null
    };
    try {
      await axios({
        url,
        data: params,
        method: 'post'
      });
    } catch (err) {
      console.log(err);
      setLoading(false);
      openToast({ type: 'error', label: t('Error scheduling report'), autoClose: 2000, theme: 'dark' });
    }
  };

  const scheduleReport = async (values: any, reloadReports: () => void): Promise<void> => {
    const promises: Array<Promise<void>> = [];
    setLoading(true);
    setShowError(false);
    if (validateEmail(values.email)) {
      if (includedData.length) {
        let valid = false;
        for (const alert in alerts) {
          if (alerts[alert as TransactionAlertName]) valid = true;
        }
        if (valid) {
          // If multiple emails create 1 row per email
          if (values.email.includes(',')) {
            const emailList = values.email.split(',');
            emailList.forEach((email: string) => {
              promises.push(createScheduledReport(values, email));
            });
            Promise.all(promises)
              .then(() => {
                handleCloseModal();
                setLoading(false);
                reloadReports();
                openToast({
                  type: 'success',
                  label: `${emailList.length} ${t('Reports scheduled')}`,
                  autoClose: 2000,
                  theme: 'dark'
                });
              })
              .catch(err => {
                console.log(err);
                setDisabled(false);
              });
            return;
          }
          promises.push(createScheduledReport(values, values.email));
          Promise.all(promises)
            .then(() => {
              handleCloseModal();
              setLoading(false);
              reloadReports();
              openToast({ type: 'success', label: t('Report scheduled'), autoClose: 2000, theme: 'dark' });
            })
            .catch(err => {
              console.log(err);
              setDisabled(false);
            });
        } else {
          setErrorMessage(t('No alert types selected'));
          setShowError(true);
          setLoading(false);
          setDisabled(false);
        }
      } else {
        setErrorMessage(t('No items selected'));
        setShowError(true);
        setLoading(false);
        setDisabled(false);
      }
    } else {
      setErrorMessage(t('Invalid email'));
      setShowError(true);
      setLoading(false);
      setDisabled(false);
    }
  };

  const editScheduledReport = async (values: any, reloadReports: () => void): Promise<void> => {
    setLoading(true);
    setShowError(false);
    if (validateEmail(values.email)) {
      if (includedData.length) {
        let valid = false;
        for (const alert in alerts) {
          if (alerts[alert as TransactionAlertName]) valid = true;
        }
        if (valid) {
          values.includedData = includedData;
          const url = `${process.env.REACT_APP_API_URL}/api/report-schedules/${existingReport?.id}`;

          let selectedReportInputs;
          if (reportTypeString === 'vehicle-group') {
            selectedReportInputs = await getVehicleIdsFromGroups();
          } else {
            selectedReportInputs = getIds();
          }

          const params = {
            account_id: appState.accountId.value,
            email: values.email.replaceAll(' ', ''),
            day: scheduledDay.toLowerCase(),
            interval: interval.toLowerCase(),
            time: values.time,
            next_timestamp: '',
            timezone,
            alerts: JSON.stringify(alerts),
            selected_ids: JSON.stringify(selectedReportInputs),
            type: reportTypeString,
            vehicle_groups: reportTypeString === 'vehicle-group'
              ? JSON.stringify(includedData.map((data: any) => {
                return data.group_id;
              }))
              : null
          };
          try {
            await axios.put(url, params);
            handleCloseModal();
            setLoading(false);
            reloadReports();
            openToast({ type: 'success', label: t('Report updated'), autoClose: 2000, theme: 'dark' });
          } catch (err) {
            console.log(err);
            openToast({ type: 'error', label: t('Error updating report'), autoClose: 2000, theme: 'dark' });
            setDisabled(false);
          }
        } else {
          setErrorMessage(t('No alert types selected'));
          setShowError(true);
          setLoading(false);
          setDisabled(false);
        }
      } else {
        setErrorMessage(t('No items selected'));
        setShowError(true);
        setLoading(false);
        setDisabled(false);
      }
    } else {
      setErrorMessage(t('Invalid email'));
      setShowError(true);
      setLoading(false);
      setDisabled(false);
    }
  };

  const validateEmail = (email: string): boolean => {
    let valid = true;
    const regex = /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}\b/;
    if (!email) {
      return false;
    }
    if (method === 'edit') {
      valid = regex.test(email);
      if (email.includes(',')) {
        valid = false;
      }
    } else {
      const emailList = email.replaceAll(' ', '').split(',');
      emailList.forEach((email: string) => {
        valid = regex.test(email);
      });
    }
    return valid;
  };

  const addAlert = (checked: boolean, alert: string): void => {
    const updateAlerts = structuredClone(alerts);
    updateAlerts[alert] = checked;
    setAlerts(updateAlerts);
  };

  const selectAllItems = (selectAll: boolean): void => {
    const updatedData: any[] = [];
    // Set all options to either be selected or deselected
    setTransformedData([...transformedData.map((item: any) => {
      if (selectAll) {
        updatedData.push(item);
      } else {
        setIncludedData([]);
      }
      item.include = selectAll;
      return item;
    })]);

    if (selectAll) {
      setIncludedData(updatedData);
    }
  };
  const getTransformedVehicles = (vehicles: any): any => {
    const transformedVehicles: any[] = [];
    const updatedIncludedData: any[] = [];
    vehicles.forEach((vehicle: any) => {
      const transformedVehicle = {
        id: vehicle.device_id,
        name: vehicle.name,
        vehicle_id: vehicle.id,
        include: existingReport ? existingReport?.selectedIds.includes(String(vehicle.device_id)) : false
      };
      if (transformedVehicle.include) {
        updatedIncludedData.push(transformedVehicle);
      }
      transformedVehicles.push(transformedVehicle);
    });
    setIncludedData(updatedIncludedData);
    return transformedVehicles;
  };

  const getTransformedGroups = (groups: any): any => {
    const transformedGroups: any[] = [];
    const updatedIncludedData: any[] = [];
    groups.forEach((group: any) => {
      const transformedGroup = {
        id: group.id,
        group_id: group.group_id,
        name: group.name,
        include: existingReport
          ? existingReport?.vehicleGroups
            ? existingReport?.vehicleGroups.includes(String(group.group_id))
            : false
          : false
      };
      if (transformedGroup.include) {
        updatedIncludedData.push(transformedGroup);
      }
      transformedGroups.push(transformedGroup);
    });
    setIncludedData(updatedIncludedData);
    return transformedGroups;
  };

  const getTransformedFuelCards = (fuelCards: any): any => {
    const transformedFuelCards: any[] = [];
    const updatedIncludedData: any[] = [];
    fuelCards.forEach((fuelCard: any) => {
      const transformedFuelCard = {
        id: fuelCard.id,
        name: fuelCard.unique_identifier,
        vehicle_name: fuelCard.vehicle_name,
        device_id: fuelCard.device_id,
        fcp: fuelCard.fcp,
        fuel_type: fuelCard.fuel_type,
        include: existingReport ? existingReport?.selectedIds.includes(fuelCard.id) : false
      };
      if (transformedFuelCard.include) {
        updatedIncludedData.push(transformedFuelCard);
      }
      transformedFuelCards.push(transformedFuelCard);
    });
    setIncludedData(updatedIncludedData);
    return transformedFuelCards;
  };

  const getTransformedDrivers = (drivers: any): any => {
    const transformedDrivers: any[] = [];
    const updatedIncludedData: any[] = [];
    drivers.forEach((driver: any) => {
      const transformedDriver = {
        id: driver.id,
        name: driver.name,
        include: existingReport ? existingReport?.selectedIds.includes(driver.id) : false
      };
      if (transformedDriver.include) {
        updatedIncludedData.push(transformedDriver);
      }
      transformedDrivers.push(transformedDriver);
    });
    setIncludedData(updatedIncludedData);
    return transformedDrivers;
  };

  const getGeotabVehicles = async (): Promise<void> => {
    setLoading(true);
    setReportTypeString('vehicle');

    try {
      const vehicleParams = {
        account_id: appState.accountId.value
      };
      const response = await fetchGeotabVehicles();
      if (response) {
        const sortedVehicles = [...response].sort((vehicleA: any, vehicleB: any) => {
          if (vehicleA.name < vehicleB.name) return -1;
          if (vehicleA.name > vehicleB.name) return 1;
          return 0;
        });
        setTransformedData(getTransformedVehicles(sortedVehicles));
        setRowCount(response.length);
      }
      setLoading(false);
    } catch (error) {
      console.log('Error fetching geotab vehicles:', error);
      setLoading(false);
    }
  };

  const fetchVehicleGroups = async (): Promise<void> => {
    setLoading(true);
    setReportTypeString('vehicle-group');
    const url = `${process.env.REACT_APP_API_URL}/api/geotab-users-geotab-vehicles-groups?filter=account_id:${appState.accountId.value}(eq),geotab_user_id:${appState.activeGeotabUserId.value}(eq)`;

    try {
      const response = await axios.get(url);
      if (response.data.data.length) {
        const sortedGroups = [...response.data.data].sort((groupA: any, groupB: any) => {
          if (groupA.name < groupB.name) return -1;
          if (groupA.name > groupB.name) return 1;
          return 0;
        });
        setTransformedData(getTransformedGroups(sortedGroups));
        setRowCount(response.data.data.length);
      }
      setLoading(false);
    } catch (error) {
      console.log('Error fetching geotab groups:', error);
      setLoading(false);
    }
  };

  const fetchFuelCards = async (): Promise<void> => {
    setLoading(true);
    setReportTypeString('fuel-card');
    try {
      const params = {
        account_id: appState.accountId.value
      };
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/fuel-cards-minimized`, params);
      if (response.data.data) {
        const sortedFuelCards = [...response.data.data].sort((fuelCardA: any, fuelCardB: any) => {
          if (fuelCardA.unique_identifier < fuelCardB.unique_identifier) return -1;
          if (fuelCardA.unique_identifier > fuelCardB.unique_identifier) return 1;
          return 0;
        });
        setTransformedData(getTransformedFuelCards(sortedFuelCards));
        setRowCount(response.data.data.length);
      }
      setLoading(false);
    } catch (error) {
      console.error('Error fetching fuel cards:', error);
      setLoading(false);
    }
  };

  const fetchDrivers = async (): Promise<void> => {
    setLoading(true);
    setReportTypeString('driver');
    const url = `${process.env.REACT_APP_API_URL}/api/drivers?filter=account_id:${appState.accountId.value}(eq)`;

    try {
      const response = await axios.get(url);
      if (response.data.data) {
        const sortedDrivers = [...response.data.data].sort((driverA: any, driverB: any) => {
          if (driverA.name < driverB.name) return -1;
          if (driverA.name > driverB.name) return 1;
          return 0;
        });
        setTransformedData(getTransformedDrivers(sortedDrivers));
        setRowCount(response.data.data.length);
      }
      setLoading(false);
    } catch (error) {
      console.error('Error fetching drivers:', error);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (method === 'edit' && existingReport) {
      switch (existingReport.type) {
        case 'vehicle':
          getGeotabVehicles();
          break;
        case 'vehicle-group':
          void fetchVehicleGroups();
          break;
        case 'fuel-card':
          void fetchFuelCards();
          break;
        case 'driver':
          void fetchDrivers();
          break;
      }
    } else {
      getGeotabVehicles();
    }
  }, []);
  const fetchReportType = (type: string): void => {
    setIncludedData([]);
    if (method === 'edit' && existingReport) {
      setExistingReport({ ...existingReport, selectedIds: [], vehicleGroups: [] });
    }
    if (type === 'Vehicles' || type === 'Vehículos') {
      getGeotabVehicles();
    } else if (type === t('Vehicle Groups')) {
      void fetchVehicleGroups();
    } else if (type === t('Fuel Cards')) {
      void fetchFuelCards();
    } else if (type === t('Drivers')) {
      void fetchDrivers();
    }
  };

  const handleDatesSubmit = async (values: any): Promise<void> => {
    setStartDate(values.from);
    setEndDate(values.to);
  };

  type ColumnsConfig = Record<string, Array<{
    name: string,
    headerText: string,
    width: number,
  }>>;

  const columnsConfig: ColumnsConfig = {
    [t('Vehicles')]: [
      { name: 'name', headerText: '', width: 250 },
      { name: 'selected', headerText: t('Selected'), width: 200 }
    ],
    [t('Vehicle Groups')]: [
      { name: 'name', headerText: '', width: 250 },
      { name: 'selected', headerText: t('Selected'), width: 200 }
    ],
    [t('Fuel Cards')]: [
      { name: 'name', headerText: '', width: 250 },
      { name: 'selected', headerText: t('Selected'), width: 200 }
    ],
    [t('Drivers')]: [
      { name: 'name', headerText: '', width: 250 },
      { name: 'selected', headerText: t('Selected'), width: 200 }
    ]
  };

  type ReportType = keyof ColumnsConfig;

  function getColumnsByReportType (reportType: ReportType): ReturnType<typeof createColumns> {
    const fields = columnsConfig[reportType];
    if (!fields) {
      throw new Error(`Invalid report type: ${reportType}`);
    }
    return createColumns(reportType, fields);
  }

  const createColumns = (headerName: any, fields: any): any => fields.map((field: any) => ({
    field: (field.name),
    headerName: `${t(headerName)} ${t(field.headerText)}`,
    width: field.width,
    ...(field.name === 'selected' && {
      renderCell: (params: any) => {
        return (
          <Switch
            key={params.row.id}
            checked={params.row.include}
            onClick={(newValue) => {
              newValue
                ? setIncludedData(includedData.concat(params.row))
                : setIncludedData(includedData.filter((item: any) => item.id !== params.row.id));
              setTransformedData(transformedData.map(item => item.id === params.row.id
                ? {
                    ...item,
                    include: newValue
                  }
                : item));
            }}
          />
        );
      }
    })
  }));

  return (
    <>
      <Modal
        header=
        {method === 'run'
          ? <div className="flex items-center gap-x-2">
            <Icon name="play" />
            <p>{t('Run Report')}</p>
          </div>
          : <div className="flex items-center gap-x-2">
            <Icon name="calendar" />
            <p>{t('Schedule Report')}</p>
          </div>}
        show={true}
        onHide={() => {
          handleCloseModal();
          setTimeout(() => {
            setDisabled(false);
          }, 500);
        }}
        maxWidth={1000}
      >
        <Formik
          enableReinitialize
          initialValues={method === 'run'
            ? {}
            : {
                id: existingReport?.id ?? '',
                email: existingReport?.email ?? '',
                time: existingReport?.time ?? '12:00:00'
              }}
          onSubmit={(values, { setSubmitting }) => {
            setDisabled(true);
            switch (method) {
              case 'edit':
                if (fetchScheduledReports) {
                  void editScheduledReport(values, fetchScheduledReports);
                }
                break;
              case 'schedule':
                if (fetchScheduledReports) {
                  void scheduleReport(values, fetchScheduledReports);
                }
                break;
              case 'run':
                void runReport(values);
                break;
              default:
                break;
            }
            setSubmitting(false);
          }}
        >
          {(props: any) => {
            return (
              <form
                onSubmit={props.handleSubmit}
                className="mt-4"
              >
                <div className="flex gap-x-6">
                  <div className="flex flex-col gap-y-6 flex-1">
                    <Input
                      name="transactionsFrom"
                      type="select"
                      label="Include transactions from"
                      defaultValue={
                        method === 'edit' && existingReport
                          ? t(existingReport?.type.includes('-')
                            ? String(existingReport?.type.replace('-', ' ').split(' ').map((word: string) => word.slice(0, 1).toUpperCase() + word.slice(1)).join(' ')) + 's'
                            : String(existingReport?.type[0]).toUpperCase() + String(existingReport?.type.slice(1)) + 's')
                          : t('Vehicles')
                      }
                      options={[
                        t('Vehicles'),
                        t('Vehicle Groups'),
                        t('Fuel Cards'),
                        t('Drivers')
                      ]}
                      onChange={setReportType}
                      handleChange={fetchReportType}
                    />
                    <div className="flex items-start flex-col gap-y-5">
                      <Input
                        type="text"
                        name="searchQuery"
                        label={`${t('Search')}: ${transformedData.filter((item) => {
                          return item.name.toUpperCase().includes(searchValue.toUpperCase());
                        }).length} ${t('results')}`}
                        setState={setSearchValue}
                        stateValue={searchValue}
                      />
                      <div className="flex items-center gap-x-3">
                        <Button
                          type="button"
                          className="!mt-0 !px-4"
                          outline
                          onClick={() => {
                            selectAllItems(true);
                          }}
                        >
                          {t('Select All')}
                        </Button>
                        <Button
                          type="button"
                          className="!mt-0 !px-4"
                          outline
                          onClick={() => {
                            selectAllItems(false);
                          }}
                        >
                          {t('Deselect All')}
                        </Button>
                      </div>
                    </div>
                    <ThemeProvider theme={theme}>
                      <CssBaseline />
                      <div ref={gridRef}>
                        {loading && (
                          <div
                            className="w-full h-full absolute inset-0 flex justify-center items-center z-[1000] bg-opacity-20 bg-black"
                          >
                            <CircularProgress />
                          </div>
                        )}
                        <DataGrid
                          hideFooter={true}
                          disableDensitySelector={true}
                          hideFooterPagination={true}
                          localeText={{
                            noRowsLabel: t('No rows'),
                            toolbarColumns: t('Columns'),
                            toolbarColumnsLabel: t('Columns'),
                            toolbarDensity: t('Density'),
                            toolbarDensityLabel: t('Density'),
                            toolbarDensityCompact: t('Compact'),
                            toolbarDensityStandard: t('Standard'),
                            toolbarDensityComfortable: t('Comfortable'),
                            toolbarQuickFilterPlaceholder: `${t('Search')}...`,
                            MuiTablePagination: {
                              labelRowsPerPage: t('Rows per Page'),
                              labelDisplayedRows: ({ from, to, count }) => `${from}-${to} ${t('of')} ${count}`
                            }
                          }}
                          rowCount={rowCount}
                          rows={transformedData.filter((item) => {
                            return item.name.toUpperCase().includes(searchValue.toUpperCase());
                          })}
                          paginationMode="server"
                          columns={getColumnsByReportType(t(reportType))}
                          disableColumnFilter
                          disableColumnMenu
                          disableRowSelectionOnClick
                          density="compact"
                          slotProps={{
                            toolbar: { // Disable Export Option
                              csvOptions: { disableToolbarButton: true },
                              printOptions: { disableToolbarButton: true }
                            }
                          }}
                          slots={{ toolbar: GridToolbar }}
                          className="flex text-sm bg-dark-3 text-white px-4 py-2 rounded-lg gap-x-6 cursor-pointer"
                          sx={{
                            height: '20rem',
                            '& .MuiDataGrid-row': {
                              borderRadius: '80px',
                              backgroundColor: '#242838',
                              marginTop: '4px',
                              borderBottom: 'none'
                            },
                            '& .MuiDataGrid-cell:focus': {
                              outline: 'none'
                            },
                            '& .MuiDataGrid-cell': {
                              border: 'none'
                            },
                            '& .MuiDataGrid-columnHeaders': {
                              borderRadius: '80px',
                              backgroundColor: '#242838',
                              borderBottom: 'none',
                              marginBottom: '10px'
                            },
                            border: 0
                          }}
                        />
                      </div>
                    </ThemeProvider>
                  </div>
                  <div className="w-px min-w-[1px] bg-dark-2"></div>
                  <div className="flex flex-col gap-y-8 pr-6">
                    {method === 'edit' || method === 'schedule'
                      ? <div className="flex flex-col gap-4">
                        <Input
                          name="email"
                          type="default"
                          label="Email"
                          placeholder="Separate emails with commas"
                        />
                        <div className="flex flex-col gap-y-2 w-full">
                          <p className="text-xs">Frequency</p>
                          <DropdownV2
                            key="schedule-report-interval-dropdown"
                            menu={(
                              <Menu
                                key="schedule-report-interval-menu"
                              >
                                {['Weekly', 'Biweekly', 'Monthly'].map((item: string, index: number) => {
                                  return (
                                    <MenuItem
                                      key={item + `${index}`}
                                      className="cursor-pointer"
                                      onClick={() => {
                                        setInterval(item);
                                        if (item === 'Weekly' || item === 'Biweekly') {
                                          setScheduledDay('Monday');
                                        } else {
                                          setScheduledDay('01');
                                        }
                                      }}
                                    >
                                      {item}
                                    </MenuItem>
                                  );
                                })}
                              </Menu>
                            )}
                          >
                            <button
                              type="button"
                              className="transition w-full bg-dark-2 focus:bg-dark-4 outline-none px-3 rounded-full h-[36px] text-xs flex items-center justify-between"
                            >
                              <span className="truncate">{interval}</span>
                              <Icon
                                name="caret-down"
                                key={'scheduledFrequencyIcon'}
                              />
                            </button>
                          </DropdownV2>
                        </div>
                        {interval === 'Weekly' || interval === 'Biweekly'
                          ? <div className="flex flex-col gap-y-2 w-full">
                            <p className="text-xs">Day</p>
                            <DropdownV2
                              key="schedule-report-day-dropdown"
                              menu={(
                                <Menu
                                  key="schedule-report-day-menu"
                                >
                                  {['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'].map((item: string, index: number) => {
                                    return (
                                      <MenuItem
                                        key={item + `${index}`}
                                        className="cursor-pointer"
                                        onClick={() => {
                                          setScheduledDay(item);
                                        }}
                                      >
                                        {item}
                                      </MenuItem>
                                    );
                                  })}
                                </Menu>
                              )}
                            >
                              <button
                                type="button"
                                className="transition w-full bg-dark-2 focus:bg-dark-4 outline-none px-3 rounded-full h-[36px] text-xs flex items-center justify-between"
                              >
                                <span className="truncate">{scheduledDay}</span>
                                <Icon
                                  name="caret-down"
                                  key={'scheduledDayIcon'}
                                />
                              </button>
                            </DropdownV2>
                          </div>
                          : <div className="flex flex-col gap-y-2 w-full">
                            <p className="text-xs">Day</p>
                            <DropdownV2
                              key="schedule-report-day-dropdown"
                              menu={(
                                <Menu
                                  key="schedule-report-day-menu"
                                  style={{ maxHeight: '300px', overflowY: 'auto' }}
                                >
                                  {['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28']
                                    .map((item: string, index: number) => {
                                      return (
                                        <MenuItem
                                          key={`${item}` + `${index}`}
                                          className="cursor-pointer"
                                          onClick={() => {
                                            setScheduledDay(item);
                                          }}
                                        >
                                          {item}
                                        </MenuItem>
                                      );
                                    })}
                                </Menu>
                              )}
                            >
                              <button
                                type="button"
                                className="transition w-full bg-dark-2 focus:bg-dark-4 outline-none px-3 rounded-full h-[36px] text-xs flex items-center justify-between"
                              >
                                <span className="truncate">{scheduledDay}</span>
                                <Icon
                                  name="caret-down"
                                  key={'scheduledDayIcon'}
                                />
                              </button>
                            </DropdownV2>
                          </div>}
                        <div className="flex flex-col gap-2">
                          <p className="text-xs">Time</p>
                          <input
                            className="transition w-full bg-dark-2 focus:bg-dark-4 outline-none px-3 rounded-full h-[36px] text-xs"
                            type="time"
                            name="time"
                            value={props.values.time}
                            onChange={props.handleChange}
                          />
                        </div>
                        <div className="flex flex-col gap-y-2 w-full">
                          <p className="text-xs">Timezone</p>
                          <DropdownV2
                            key="schedule-report-interval-dropdown"
                            menu={(
                              <Menu
                                key="schedule-report-interval-menu"
                              >
                                {['US/Eastern',
                                  'US/Central',
                                  'US/Mountain',
                                  'US/Pacific',
                                  'US/Alaska',
                                  'US/Hawaii'].map((item: string, index: number) => {
                                  return (
                                      <MenuItem
                                        key={item + `${index}`}
                                        className="cursor-pointer"
                                        onClick={() => {
                                          setTimezone(item);
                                        }}
                                      >
                                        {item}
                                      </MenuItem>
                                  );
                                })}
                              </Menu>
                            )}
                          >
                            <button
                              type="button"
                              className="transition w-full bg-dark-2 focus:bg-dark-4 outline-none px-3 rounded-full h-[36px] text-xs flex items-center justify-between"
                            >
                              <span className="truncate">{timezone}</span>
                              <Icon
                                name="caret-down"
                                key={'scheduledFrequencyIcon'}
                              />
                            </button>
                          </DropdownV2>
                        </div>
                      </div>
                      : <Calendar
                        small={true}
                        handleDatesSubmit={handleDatesSubmit}
                        hideSubmitButton={true}
                      />
                    }
                    <div className="flex flex-col gap-y-2">
                      <p className="font-bold text-sm">Alert Types</p>
                      <div className="flex items-center gap-x-2">
                        <Switch
                          checked={alerts.no_vehicle_location}
                          onClick={(checked) => {
                            addAlert(checked, 'no_vehicle_location');
                          }}
                        />
                        <div className="flex items-center gap-x-1">
                          <div
                            className={`${iconColorMap[iconTypeMapping.no_vehicle_location]} w-[1.1rem] flex items-center justify-center`}
                          >
                            <Icon name={iconTypeMapping.no_vehicle_location} />
                          </div>
                          <p className="text-sm">{t('No Vehicle Location')}</p>
                        </div>
                      </div>
                      <div className="flex items-center gap-x-2">
                        <Switch
                          checked={alerts.location_mismatch}
                          onClick={(checked) => {
                            addAlert(checked, 'location_mismatch');
                          }}
                        />
                        <div className="flex items-center gap-x-1">
                          <div
                            className={`${iconColorMap[iconTypeMapping.location_mismatch]} w-[1.1rem] flex items-center justify-center`}
                          >
                            <Icon name={iconTypeMapping.location_mismatch} />
                          </div>
                          <p className="text-sm">{t('Location Mismatch')}</p>
                        </div>
                      </div>
                      <div className="flex items-center gap-x-2">
                        <Switch
                          checked={alerts.fuel_type_mismatch}
                          onClick={(checked) => {
                            addAlert(checked, 'fuel_type_mismatch');
                          }}
                        />
                        <div className="flex items-center gap-x-1">
                          <div
                            className={`${iconColorMap[iconTypeMapping.fuel_type_mismatch]} w-[1.1rem] flex items-center justify-center`}
                          >
                            <Icon name={iconTypeMapping.fuel_type_mismatch} />
                          </div>
                          <p className="text-sm">{t('Fuel Type Mismatch')}</p>
                        </div>
                      </div>
                      <div className="flex items-center gap-x-2">
                        <Switch
                          checked={alerts.fuel_level_mismatch}
                          onClick={(checked) => {
                            addAlert(checked, 'fuel_level_mismatch');
                          }}
                        />
                        <div className="flex items-center gap-x-1">
                          <div
                            className={`${iconColorMap[iconTypeMapping.fuel_level_mismatch]} w-[1.1rem] flex items-center justify-center`}
                          >
                            <Icon name={iconTypeMapping.fuel_level_mismatch} />
                          </div>
                          <p className="text-sm">{t('Fuel Level Mismatch')}</p>
                        </div>
                      </div>
                      <div className="flex items-center gap-x-2">
                        <Switch
                          checked={alerts.filling_time_alert}
                          onClick={(checked) => {
                            addAlert(checked, 'filling_time_alert');
                          }}
                        />
                        <div className="flex items-center gap-x-1">
                          <div
                            className={`${iconColorMap[iconTypeMapping.filling_time_alert]} w-[1.1rem] flex items-center justify-center`}
                          >
                            <Icon name={iconTypeMapping.filling_time_alert} />
                          </div>
                          <p className="text-sm">{t('Filling Time Alert')}</p>
                        </div>
                      </div>
                      <div className="flex items-center gap-x-2">
                        <Switch
                          checked={alerts.filling_percent_alert}
                          onClick={(checked) => {
                            addAlert(checked, 'filling_percent_alert');
                          }}
                        />
                        <div className="flex items-center gap-x-1">
                          <div
                            className={`${iconColorMap[iconTypeMapping.filling_percent_alert]} w-[1.1rem] flex items-center justify-center`}
                          >
                            <Icon name={iconTypeMapping.filling_percent_alert} />
                          </div>
                          <p className="text-sm">{t('Filling Percent Alert')}</p>
                        </div>
                      </div>
                      {showAlerts && (
                        <div className="flex items-center gap-x-2">
                          <Switch
                            checked={alerts.tank_capacity_mismatch}
                            onClick={(checked) => {
                              addAlert(checked, 'tank_capacity_mismatch');
                            }}
                          />
                          <div className="flex items-center gap-x-1">
                            <div
                              className={`${iconColorMap[iconTypeMapping.tank_capacity_mismatch]} w-[1.1rem] flex items-center justify-center`}
                            >
                              <Icon name={iconTypeMapping.tank_capacity_mismatch} />
                            </div>
                            <p className="text-sm">{t('Tank Capacity Mismatch')}</p>
                          </div>
                        </div>
                      )}
                      {showAlerts && (
                        <div className="flex items-center gap-x-2">
                          <Switch
                            checked={alerts.out_of_state_alert}
                            onClick={(checked) => {
                              addAlert(checked, 'out_of_state_alert');
                            }}
                          />
                          <div className="flex items-center gap-x-1">
                            <div
                              className={`${iconColorMap[iconTypeMapping.out_of_state_alert]} w-[1.1rem] flex items-center justify-center`}
                            >
                              <Icon name={iconTypeMapping.out_of_state_alert} />
                            </div>
                            <p className="text-sm">{t('Out of State Alert')}</p>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="flex">
                  <div className="flex items-end gap-4 min-w-[60%] mt-6">
                    <button
                      disabled={disabled}
                      className="transition text-sm h-[28px] px-8 rounded-full bg-custom-blue-normal hover:bg-custom-blue-hover text-white"
                      type="submit"
                    >
                      {t('Submit')}
                    </button>
                    {showError && <p className="text-error-3">{errorMessage}</p>}
                  </div>
                  {loading && (
                    <div
                      className="w-full h-full absolute inset-0 flex justify-center items-center z-[1000] bg-opacity-20 bg-black"
                    >
                      <CircularProgress />
                    </div>
                  )}
                </div>
              </form>
            );
          }}
        </Formik>
      </Modal>
    </>
  );
};

export default withTranslation()(ReportModal);
