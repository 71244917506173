import {
  type FuelCard,
  type FuelCardResponse,
  type FuelCardProvider,
  type PreferredMatch
} from '../types/fcp';
import { t } from 'i18next';
import { type TransactionAlerts, type TransactionAlertName } from './types';

export const fuelCardProviderOptions: Array<{ key: string, value: FuelCardProvider, }> = [
  { key: 'WEX', value: 'WEX' },
  { key: 'EFS', value: 'EFS' },
  { key: 'Comdata', value: 'COMDATA' },
  { key: 'Voyager', value: 'VOYAGER' },
  { key: 'Fuelman', value: 'FUELMAN' },
  { key: 'GIR', value: 'GIR' },
  { key: 'PetroCanada', value: 'PETRO' },
  { key: 'Multiservice', value: 'MULTISERVICE' },
  { key: 'Jacobus Energy', value: 'JACOBUS' },
  { key: 'SC Fuel', value: 'SC' },
  { key: 'Bluearrow Fuel Tracker', value: 'BLUEARROW FUEL TRACKER' },
  { key: 'Geotab Fuel Tracker', value: 'FUELTRACKER' },
  { key: 'DC&H', value: 'DCH' },
  { key: 'Flyer\'s Energy', value: 'FLYERS' },
  { key: 'Hunt and Sons', value: 'HUNT' },
  { key: 'Love\'s', value: 'LOVES' },
  { key: 'Ramos Oil', value: 'RAMOS' },
  { key: 'Coast', value: 'COAST' },
  { key: 'Valley Wide', value: 'VALLEY' },
  { key: 'PetroCard', value: 'PETROCARD' },
  { key: 'CA Fuel', value: 'CA' },
  { key: 'Sapphire', value: 'SAPPHIRE' },
  { key: 'FuelCloud', value: 'FUELCLOUD' },
  { key: 'Sears', value: 'SEARS' },
  { key: 'BVD', value: 'BVD' },
  { key: 'Jubitz', value: 'JUBITZ' }
];

export const formatOptions = (option: string): string => {
  return option.replaceAll('_', ' ')
    .split(' ')
    .map((word: string): string => {
      if (word === 'id' || word === 'vin') {
        return word.toUpperCase();
      }
      return word[0].toUpperCase() + word.slice(1);
    })
    .join(' ');
};

export const capitalizeFirstLetter = (string: string): string => {
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
};

export const transformFuelCards = (fuelCardData: FuelCardResponse[]): FuelCard[] => {
  return fuelCardData.map((fuelCard: FuelCardResponse): FuelCard => {
    // Convert bin from json to bool
    if (fuelCard.fuel_card_alerts_to_ignore) {
      const { id, ...alerts } = fuelCard.fuel_card_alerts_to_ignore as { id: number, } & TransactionAlerts;
      Object.keys(alerts).forEach(key => {
        alerts[key as TransactionAlertName] = Boolean(alerts[key as TransactionAlertName]);
      });
      fuelCard.fuel_card_alerts_to_ignore = { id, ...alerts };
    }

    return {
      id: fuelCard.id,
      accountId: fuelCard.account_id,
      fuelCardAlertsToIgnore: fuelCard.fuel_card_alerts_to_ignore,
      match: fuelCard.preferred_match === 'vehicle'
        ? fuelCard.geotab_vehicle ?? { id: -1, name: t('Vehicle not assigned') }
        : fuelCard.geotab_driver ?? { id: -1, name: t('Driver not assigned') },
      uniqueIdentifier: fuelCard.unique_identifier,
      fuelType: fuelCard.fuel_type,
      fuelCardProvider: fuelCard.fcp as FuelCardProvider,
      preferredMatch: fuelCard.preferred_match as PreferredMatch
    };
  });
};
